@import '../../assets/styles/_variables';

.wcag {
  overflow: hidden;
  float: left;
  padding: 0 2px 0 0;

  a {
    text-indent: -9999px;
    overflow: hidden;
    float: left;
    width: 26px;
    height: 26px;
    margin: 5px 0 2px 15px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    background: #fff;
    border-radius: 100%;
    transition: all 300ms;
    position: relative;
    line-height: 100px;
    cursor: pointer;

    &:before {
      content: '';
      float: left;
      text-indent: 0;
      width: 26px;
      height: 26px;
      font-size: 1rem;
      line-height: 26px;
      text-align: center;
      color: #000;
      font-weight: bold;
    }

    &.font-size {
      &:before {
        content: 'A+';
      }
    }
    &.contrast {
      background: #ff0;

      &:before {
        background: url(../../assets/images/wheelchair.svg) no-repeat 50% 50%;
        background-size: 50%;
      }

      &:hover {
        &:before {
          background-image: url(../../assets/images/wheelchair-white.svg);
        }
      }
    }

    &:hover {
      background: #ed1c24;

      &:before {
        color: #fff;
      }
    }
  }
}

.contrast-black-yellow {
  background: #000;
  color: #ff0;

  a {
    color: #fff;

    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }

  main {
    background: #000;
  }

  .main-header {
    .hamburger {
      span,
      &:before,
      &:after {
        background: #ff0;
      }
    }
  }

  .top-bar {
    .navigation {
      a {
        color: #ff0;

        &:hover {
          color: #fff;
          text-decoration: underline;
        }
      }
    }
  }

  .logo {
    color: #ff0;

    &:hover {
      color: #fff;
    }
  }

  .slot2 {
    a {
      color: #ff0;
      background: transparent;

      &:focus {
        outline: none !important;
        border: 1px solid red !important;
      }
      &:hover,
      &.selected {
        text-decoration: none;
        color: #fff;
        border-color: #fff !important;
      }
    }
  }

  div.text {
    h2 {
      &:before {
        border-color: #ff0;
      }
    }
  }

  .page {
    h3 {
      &:before {
        border-color: #ff0;
      }
    }
  }

  main {
    aside {
      .slot7,
      .slot8 {
        > * {
          border: 1px solid #ff0;
          background: transparent;
        }
      }
    }
  }

  main {
    .navigation {
      a {
        color: #ff0;

        &:hover {
          color: #fff;
          background: transparent;
          text-decoration: underline;
        }
      }
    }
  }

  .btn {
    background: #ff0 !important;
    color: #000 !important;

    &:before {
      background-position: 0 -55px !important;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .slot1 {
    .login-box {
      .info {
        color: #ff0;
      }

      .content {
        .btn.small {
          background: transparent !important;
          color: #ff0 !important;

          &:before {
            background-position: 0 5px !important;
          }
        }
      }
    }
  }

  .slot4-container {
    background: #000;
    border-top: 1px solid #ff0;

    .logos {
      div {
        border-color: #ff0;
      }
    }
  }

  .main-footer {
    background: #000;

    .slot6 {
      color: #ff0;
      border-color: #ff0;
    }

    nav {
      a {
        color: #ff0;
      }
    }
  }

  .wcag {
    .font-size {
      background: #ff0;

      &:hover {
        background: #fff;

        &:before {
          color: #000;
        }
      }
    }

    .contrast {
      &:hover {
        background: #fff;

        &:before {
          background-image: url(../../assets/images/wheelchair.svg);
        }
      }
    }
  }

  .form {
    border: 1px solid #ff0;
    background: transparent;

    > div {
      border: 1px solid #ff0;
      background: transparent;
    }

    h3 {
      color: #ff0;
    }

    .checkbox {
      label {
        &:before {
          border-color: #ff0;
          background: #000;
        }
      }
    }
  }

  :focus {
    outline: 2px solid #ed1c24 !important;
  }
  .home .btn:focus,
  .swiper-slide.magazine a:focus {
    outline: 2px solid #ed1c24 !important;
  }
  .btn:focus {
    outline: 2px solid #ed1c24 !important;
  }
  .btn.black:focus {
    outline: 2px solid #ed1c24 !important;
  }

  input,
  select,
  textarea {
    border: 1px solid #ff0;
    background: transparent;
    color: #ff0;
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #ff0;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: #ff0;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: #ff0;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: #ff0;
  }

  blockquote {
    color: #000;
    border-color: #ff0;
  }

  .ql-syntax {
    background: #ff0;
    color: #000;
  }

  .messages {
    > div {
      border: 1px solid#ff0 !important;
      background: #000 !important;
      color: #ff0 !important;
    }
  }

  .slot3 {
    .descrption-box {
      h2 {
        color: #ff0;

        &:before {
          border-color: #ff0;
        }
      }
      p {
        color: #ff0;
      }
    }
  }

  .archive-box {
    background: #000;
    border: 1px solid#ff0;

    h2 {
      color: #ff0;
    }

    > form {
      background: #000;
      border: 1px solid#ff0;
    }
  }

  .select__control {
    background: #000;
    border: 1px solid #ff0 !important;
    color: #ff0;
    outline: none !important;
    box-shadow: none !important;

    .select__value-container,
    .select__value-container > div {
      color: #ff0 !important;
    }

    &.select__control--is-focused {
      outline: 2px solid #fff !important;
    }
  }

  .select__menu {
    background: #ff0;

    .select__option {
      color: #000;

      &.select__option--is-selected {
        background: #000;
        color: #ff0;
      }

      &.select__option--is-focused,
      &:hover {
        background: #000;
        color: #ff0;
      }
    }
  }

  .magazine-box {
    a {
      &.prev,
      &.next {
        background: #ff0;
      }
    }

    .swiper-container {
      .swiper-wrapper {
        .swiper-slide.magazine {
          h3 {
            color: #ff0;

            &:before {
              border-color: #ff0;
            }
          }
          p {
            color: #ff0;
          }
          a {
            background: #ff0;
            color: #000;
            border: 0;

            &:hover {
              background: #dc0316;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .magazine {
    .search-box {
      border: 1px solid #ff0;
      background: #000;

      > form {
        border: 1px solid #ff0;
        background: #000;
      }

      &.search-box-bottom {
        input.text {
          border: 1px solid #ff0;
          background: #000;
          color: #ff0;
        }
      }
    }

    .years {
      .accordion {
        > .panel {
          border-color: #ff0 !important;
          background: #000 !important;

          .header {
            color: #ff0;
            background: #000;

            &::after {
              background: transparent;
              content: '>';
              transform: none;
              font-family: monospace;
              top: 0;
            }
          }

          .content {
            a {
              background: #ff0;
            }
          }
        }
      }
    }
  }

  .magazine-view {
    .years {
      border: 1px solid #ff0;
      background: #000;

      .top,
      .bottom {
        background: #000;
        border-color: #ff0;

        &:before {
          background-image: url(../../assets/images/arrow-yellow.svg);
        }

        &:hover {
          background: #ff0;

          &:before {
            background-image: url(../../assets/images/arrow-black.svg);
          }
        }
      }

      .group {
        h3 {
          border-color: #ff0;

          &:hover {
            background: #ff0;
            color: #000;
          }
        }

        a {
          color: #ff0;

          &:hover {
            background: #ff0;
            color: #000;
          }
        }

        > div.active {
          border-color: #ff0;
          background: #000;
        }
      }
    }

    article {
      .months {
        .month {
          background: #ff0;
        }
      }
    }

    .box {
      > div {
        background: #000;
        border: 1px solid #ff0;
      }
    }

    &.month-view {
      .months {
        .magazine {
          .month {
            .number {
              background: #ff0;
              color: #000;
            }

            &:hover {
              .number {
                color: #fff;
              }
            }
          }
        }
      }
    }

    .covers {
      .top,
      .bottom {
        background: #000;
        border-color: #ff0;

        &:before {
          background-image: url(../../assets/images/arrow-yellow.svg);
        }

        &:hover {
          background: #ff0;

          &:before {
            background-image: url(../../assets/images/arrow-black.svg);
          }
        }
      }
    }

    &.page-view {
      .covers {
        .cover {
          .number {
            background: #ff0;
            color: #000;
          }

          &:hover,
          &.active {
            background: #ff0;

            .number {
              background: #000;
              color: #ff0;
            }
          }
        }
      }

      article {
        background: #000;

        @media only screen and (min-width: $desktop) {
          border: 1px solid #ff0;
        }

        .switch-btn {
          background: #fff;

          a {
            background: #fff;

            &:hover,
            &.selected {
              background: #ff0;
            }
          }
        }

        .mark-btn {
          background: #ff0;

          &:hover {
            background: #fff;
          }
        }

        .actions {
          border-color: #ff0;

          a {
            color: #ff0;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        .document {
          .document-text {
            background: #000;
          }

          .prev-page,
          .next-page {
            background-color: #ff0;
            background-image: url(../../assets/images/arrow-black.svg);
          }

          .btn-corrections {
            &:before {
              background-position: -102px -76px !important;
            }
          }
        }
      }
    }
  }

  .breadcrumbs {
    background: #000;
    border-top: 1px solid #ff0;
    border-bottom: 1px solid #ff0;

    a {
      color: #ff0;

      &:before {
        color: #ff0;
        background-position: 0 -105px;

        @media only screen and (min-width: $desktop) {
          background: transparent url(../../assets/images/arrow-yellow.svg) no-repeat 0 0;
        }
      }
    }

    span {
      color: #ff0;
    }
  }

  .magazine-info {
    background: #000;
    border: 1px solid #ff0;
  }
}

.font-size-1 {
  font-size: 15px;
}

.font-size-2 {
  font-size: 17px;
}

.font-size-1,
.font-size-2 {
  .slot2 {
    a {
      @media only screen and (min-width: $desktop) {
        padding-left: 6px;
        padding-right: 6px;
      }
    }
  }

  .select {
    @media only screen and (min-width: $tablet) {
      margin-right: 2%;
    }
    @media only screen and (min-width: $desktop) {
      margin-right: 5%;
    }
    @media only screen and (min-width: $xdesktop) {
      margin-right: 7%;
    }
  }
}
