@import '../../../assets/styles/_variables';

#consent {
  position: fixed;
  z-index: 8010;
  bottom: 0;
  background: #fff;
  color: #000;
  font-size: 1rem;
  line-height: 1rem;
  left: 0;
  right: 0;
  text-align: center;
  padding: 10px;
  border-top: 1px solid #888;

  p {
    margin: 0 0 5px;
  }

  a {
    background: #fff;
  }
}
