@import '../../assets/styles/_variables';

.home {
  .main-header {
    background: transparent;
  }

  main {
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 60%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0) 100%);

    padding-left: 0;
    padding-right: 0;
    position: relative;
    z-index: 102;
    min-height: 1000px;

    @media only screen and (min-width: $desktop) {
      /* min-height: 1400px; */
      background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 75%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0) 100%);
    }

    @media only screen and (min-width: $xdesktop) {
      background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 30%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%);
    }

    .row {
      overflow: visible;
    }
  }
}

.archive-box {
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.5);
  margin: 47px 26px;

  @media only screen and (min-width: $desktop) {
    margin: 0 auto 120px;
    width: 77%;
  }

  form {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    padding: 34px 34px 0;

    @include clearfix;

    @media only screen and (min-width: $desktop) {
      padding: 27px 67px;
    }

    input.text {
      height: 41px;
      line-height: 41px;
      font-size: 1rem;
      font-weight: normal;
      color: #000;
      padding: 0 10px;
      margin: 0 0 30px;
      border-radius: 5px;
      background: #fff;
      border: 1px solid #eaeaea;
      width: 100%;

      @media only screen and (min-width: $tablet) {
        width: 70%;
        margin: 0 4%;
        float: left;
      }

      @media only screen and (min-width: $desktop) {
        margin: 0 8% 10px 2%;
        height: 49px;
        line-height: 49px;
        font-size: 1.0714285714285714rem;
      }

      @media only screen and (min-width: $xdesktop) {
        width: 72%;
      }
    }

    .select__control--is-focused {
      outline: none !important;
    }

    .btn {
      position: relative;
      overflow: hidden;

      @media only screen and (min-width: $desktop) {
        float: left;
        padding: 0 51px 0 18px;
      }

      &:focus {
        outline: none !important;
      }
    }
  }

  h2 {
    color: #fff;
    font-size: 1.4285714285714286rem;
    text-align: center;
    margin: 0;
    font-weight: normal;
    padding: 39px 0 33px;

    @media only screen and (min-width: $desktop) {
      padding: 43px 0 35px;
    }
  }
}

.slot3 {
  .descrption-box {
    padding: 0 0 50px;
    overflow: hidden;

    @media only screen and (min-width: $desktop) {
      padding: 0 0 94px;
    }

    h2 {
      color: #fbfbfb;

      font-size: 1.7857142857142858rem;
      line-height: 1.7777777777777777rem;
      text-align: center;
      margin: 0 0 36px;
      padding: 0 0 23px;
      position: relative;

      @media only screen and (min-width: $desktop) {
        font-size: 2.142857142857143rem;
        margin: 0 0 14px;
        padding: 0 0 42px;
      }

      &:before {
        content: '';
        position: absolute;
        border: 1px solid #707070;
        width: 50px;
        top: 100%;
        left: 50%;
        margin: 0 0 0 -25px;
      }
    }

    div {
      margin: 0 auto;

      @media only screen and (min-width: $desktop) {
        width: 85%;
      }
    }

    p {
      color: #fbfbfb;
      font-size: 0.8571428571428571rem;
      letter-spacing: 1.1px;
      line-height: 1.3888888888888888rem;
      margin: 0;
      padding: 0 20px;

      @media only screen and (min-width: $desktop) {
        width: 50%;
        float: left;
        padding: 23px 40px;
        font-size: 1.0714285714285714rem;
        line-height: 2.0555555555555554rem;
        letter-spacing: 1px;
      }
    }
  }
}

.magazine-box {
  position: relative;
  height: 538px;
  width: 320px;
  margin: 0 auto 50px;
  background: #000;
  border-radius: 10px;
  padding: 46px 0 0;

  @media only screen and (min-width: $desktop) {
    width: 705px;
    height: 438px;
    padding: 46px 55px;
    margin-bottom: 115px;
    margin: o auto;
  }

  a.prev,
  a.next {
    position: absolute;
    display: block;
    width: 46px;
    height: 46px;
    background: #ffffff;
    border-radius: 100%;
    top: 101px;
    font-size: 1.7857142857142858rem;
    line-height: 46px;
    text-align: center;
    color: #000;
    font-family: monospace;
    transition: all 500ms;
    z-index: 999;
    text-decoration: none;

    @media only screen and (min-width: $desktop) {
      top: 50%;
      margin: -45px 0 0;
      width: 60px;
      height: 60px;
      font-size: 2.5rem;
      line-height: 58px;
    }

    &:hover {
      background: #dc0316;
      color: #fff;
    }
  }

  a.prev {
    left: 27px;

    @media only screen and (min-width: $desktop) {
      left: -32px;
    }
  }
  a.next {
    right: 27px;

    @media only screen and (min-width: $desktop) {
      right: -32px;
    }
  }

  .swiper-container {
    overflow: hidden;
    width: 320px;

    @media only screen and (min-width: $desktop) {
      width: 600px;
    }

    .swiper-wrapper {
      display: grid;
      grid-auto-flow: column;
      width: 320px;

      @media only screen and (min-width: $desktop) {
        width: 600px;
      }

      .swiper-slide.magazine {
        width: 320px;
        text-align: center;

        @media only screen and (min-width: $desktop) {
          width: 600px;
          text-align: left;
        }

        div {
          overflow: hidden;
          padding: 0 0 2px;
        }

        img {
          width: 119px;
          border-radius: 20px;
          margin: 0 auto 22px;
          display: block;

          @media only screen and (min-width: $desktop) {
            width: 225px;
            float: left;
            margin: 0 70px 0 0;
          }
        }

        h3 {
          color: #fff;
          margin: 23px 0 32px;
          padding: 0 0 16px;
          font-size: 1rem;
          position: relative;
          letter-spacing: 0.5px;
          line-height: 1.5rem;

          @media only screen and (min-width: $desktop) {
            font-size: 1.2857142857142858rem;
            line-height: 1.7rem;
            margin: 23px 0 37px;
            padding: 0 0 24px;
          }

          &:before {
            content: '';
            position: absolute;
            border: 1px solid #707070;
            width: 50px;
            top: 100%;
            left: 50%;
            margin: 0 0 0 -25px;

            @media only screen and (min-width: $desktop) {
              left: 0;
              margin: 0;
            }
          }
        }

        p {
          color: #fbfbfb;
          font-size: 0.8571428571428571rem;
          line-height: 1rem;
          margin: 0 20px 32px;
          letter-spacing: 1px;

          @media only screen and (min-width: $desktop) {
            float: left;
            font-size: 1.0714285714285714rem;
            line-height: 1.6666666666666667rem;
            margin: 0 0 35px;
          }
        }

        a {
          padding: 10px 27px;
          border: 2px solid #dc0316;
          border-radius: 3px;
          color: #fff;
          background: #dc0316;
          display: block;
          font-size: 0.9285714285714286rem;
          font-weight: bold;
          margin: 0 auto;
          text-decoration: none;
          transition: all 500ms;
          width: 102px;
          clear: both;

          @media only screen and (min-width: $desktop) {
            padding: 15px 37px;
            width: 128px;
            font-size: 1.0714285714285714rem;
          }

          &:hover {
            background: #fff;
            border-color: #fff;
            color: #000;
          }
        }
      }
    }
  }
}

.player-box {
  margin: 0 auto -100px;
  border-radius: 20px;
  background: #e60000;
  position: relative;
  text-align: center;
  clear: both;
  display: block;
  width: 270px;
  padding: 10px 0;

  @media only screen and (min-width: $tablet) {
    margin-bottom: -100px;
  }

  @media only screen and (min-width: $desktop) {
    width: 450px;
    padding: 30px;
    margin-bottom: -119px;
  }
}
