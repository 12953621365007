.form {
  background: #fbfbfb;
  border-radius: 20px;
  margin: 81px auto 15px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

  @media only screen and (min-width: $desktop) {
    max-width: 76%;
  }

  &.pendding {
    .btn:not(.black) {
      &:before {
        display: none;
      }
      &:after {
        content: '';
        position: absolute;
        top: 7px;
        right: 8px;
        width: 25px;
        height: 25px;
        margin: 0;
        background: transparent;

        display: inline-block;
        padding: 0px;
        border-radius: 100%;
        border: 5px solid;
        border-top-color: rgb(255, 0, 0);
        border-bottom-color: #fff;
        border-left-color: rgb(255, 0, 0);
        border-right-color: #fff;
        animation: loader 1s ease-in-out infinite;

        @media only screen and (min-width: $desktop) {
          top: 13px;
          right: 10px;
        }
      }
    }
  }

  > div {
    background: #fff;
    border-radius: 20px;
    padding: 60px 30px 20px;
    overflow: hidden;

    @media only screen and (min-width: $desktop) {
      padding: 40px 90px;
    }

    > div {
      position: relative;

      &.full-width {
        width: 100% !important;
      }

      &:nth-child(odd) {
        @media only screen and (min-width: $tablet) {
          float: left;
          width: 44%;
          clear: both;
        }
      }

      &:nth-child(even) {
        @media only screen and (min-width: $tablet) {
          float: right;
          width: 44%;
        }
      }

      &.full {
        width: auto;
        float: none;
        clear: both;
      }

      &.center {
        text-align: center;
      }
    }
  }

  h3 {
    color: #000;
    font-size: 1.4285714285714286rem;
    letter-spacing: 2px;
    text-align: center;
    margin: 0;
    padding: 40px 0;
    font-weight: normal;
    line-height: 2rem;
  }

  input,
  select,
  textarea {
    padding: 13px 31px;
    margin: 0 0 36px;
    background: #fff;
    font-size: 1rem;
    border: 2px solid #d7d7d7;
    border-radius: 5px;
    width: 100%;
    color: #000;
  }

  textarea {
    height: 200px;
    resize: none;

    //@media only screen and (min-width: $desktop) {
    //  height: 183px;
    //}
  }

  .checkbox {
    label {
      margin-bottom: 36px;
    }

    input[type='checkbox'] {
      float: left;
      width: auto;
      margin-right: 5px;
      margin-top: 3px;
    }
  }

  .radio {
    margin: 0 0 36px;
    text-align: left;

    @media only screen and (min-width: $tablet) {
      width: 100% !important;
      text-align: right;
    }

    label {
      display: block;
      position: relative;
      margin: 0 40px 20px 0;
      padding: 0 0 0 30px;
      cursor: pointer;
      line-height: 1.1666666666666667rem;

      @media only screen and (min-width: $tablet) {
        display: inline-block;
        margin-bottom: 0;
      }

      &:last-child {
        margin-right: 0;
        margin-bottom: 0;
      }

      &:before {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        left: 0;
        top: 0;
        border-radius: 100%;
        background: #fff;
        border: 2px solid #d7d7d7;
      }

      &:after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background: transparent;
        top: 5px;
        left: 5px;
        border-radius: 100%;
        transition: all 200ms;
      }
    }

    input[type='radio'] {
      float: left;
      width: 20px;
      height: 20px;
      margin: 0;
      display: none;
    }

    input[type='radio']:checked + label {
      &:before {
        animation: blink 200ms ease-in-out;
        animation-fill-mode: forwards;
      }

      &:after {
        background: #dc0316;
      }
    }
  }

  .checkbox {
    margin: 0 0 36px;
    text-align: left;
    position: relative;

    @media only screen and (min-width: $tablet) {
      width: 100% !important;
    }

    label {
      display: block;
      position: relative;
      margin: 0 0px 20px 0;
      padding: 0 0 0 30px;
      cursor: pointer;
      line-height: 1.1666666666666667rem;
      float: left;

      @media only screen and (min-width: $tablet) {
        display: inline-block;
        margin-bottom: 0;
      }

      &:last-child {
        margin-right: 0;
        margin-bottom: 0;
      }

      &:before {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        left: 0;
        top: 0;
        background: #fff;
        border: 2px solid #d7d7d7;
      }

      &:after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background: transparent;
        top: 5px;
        left: 5px;
        transition: all 200ms;
      }
    }

    input[type='checkbox'] {
      float: left;
      width: 20px;
      height: 20px;
      margin: 0;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }

    input[type='checkbox']:checked + label {
      &:before {
        animation: blink 200ms ease-in-out;
        animation-fill-mode: forwards;
      }

      &:after {
        background: #dc0316;
      }
    }

    input[type='checkbox']:focus + label {
      &:before {
        outline: 2px solid #ed1c24 !important;
      }
    }
  }

  .error-message {
    color: #ed1c24;
    margin: -28px 0 11px 3px;
    clear: both;
  }

  .captcha {
    margin: 0 0 36px;
  }
}

.btn {
  padding: 0 50px 0 19px;
  margin: 0 auto 43px;
  font-size: 0.9285714285714286rem;
  width: auto;
  text-align: left;
  display: block;
  font-weight: bold;
  color: #fff;
  border: 0;
  border-radius: 5px;
  background: #dc0316;
  height: 39px;
  line-height: 39px;
  position: relative;
  display: inline-block;
  vertical-align: top;

  @media only screen and (min-width: $desktop) {
    padding: 0 62px 0 19px;
    margin: 0 auto;
    font-size: 1.0714285714285714rem;
    height: 49px;
    line-height: 49px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 8px;
    right: 5px;
    width: 30px;
    height: 30px;
    background: url(../../assets/images/assets.png) no-repeat 0 5px;
    transition: all 300ms;

    @media only screen and (min-width: $desktop) {
      top: 13px;
      right: 10px;
    }
  }

  &:hover {
    color: #fff;
    text-decoration: none;

    &:before {
      right: 0;
    }
  }
  &.link {
    background: none;
    color: #212529;
    &:hover {
      text-decoration: underline;
    }
    &.small {
      font-size: 0.8rem;
    }
  }
  &.white {
    color: #fff;
  }

  &.force-left {
    float: left !important;
  }
  &.black {
    background: #000;
  }
  &.small {
    font-size: 0.9285714285714286rem;

    &:before {
      top: 5px !important;
    }
  }
  &.google {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAFN0lEQVRYR+WYW2wUZRTHz5nLXkrZQtttCkKACEL1BaRUDELWorVdTIDWxvDANRgS8UVBAREYUwgKBB6ARIwXLipIQ1sptJQWAkLUcG3AQAwkYrAN0IVSure5HjMNLZ3d7e7ObENI/B53z/9/fnO++S5nEJ7xgc84H/w/AP/2eBxOm+TiFOJ5HkIun9SJFy/K/TE7lirY6pmYzTG4FhDnAUBGHyBEALcRcYt7sH8XVl6TrACbAmx7syCfVKoDBLeFZOftHFec0fD7AzPapABvlIy2Z4iDmwFhnBnzWLGIuM/ddE6vfFIjIaCvML9AQ/wDoF8XVCeogaE5p675E1HGBbxXWFAOSAcTmCgAdAMIrxJAByLkAsB4ABiW4KEI1IArEWSfgG3TX3mdQDsZB+6AneOWxnunfEUTx2kqs/8xsMEKiQT3yQufW6pg+6zxg+ROvr0PcXOHrX3ymPqbYiLz7v9bCyeM4JD/E4DS9d+SheuKjZVEbrTdDzYNzRQvRCxWhDU5TefXJwsWGXdv+qTfkKghmcp1a6MApVrHQnSq3+kB8q2B4N//fFcsIn3kbrqwzSqcVV0UoNzIK4DAdhuSyELHzhfr3XWXvFaTpKIzAMqHHYWQpp6IMCRuuswiAqWSyKrWCHjcdh0YMm7GIreMnxHaajVBqjojYBMfVSXurMyiAFqqiazqewCpamCW4gr7ehuRhv/YiqSRVs37Q9cDKNfai8CpNfQ21ST2S7s3vNJsorfX+55jARmzOj1+gpLZIgjYM2NPAA87l0OastlgKjIl/AzxmNlExV90thLgELM6PZ5XwsOOfJbdErUPyo3cOkAUDFPcwRfYyoLnzSZKBXAAisOrVmT9Gw14xLEaHKrxlJBYD+8Nn36agK5w25BKYdSdKECx1vkO41QqDTASruC90qanCni3Pa1y2/BQFGDokGMEl6HeMsCo2My/JU0wC1iyseMSIaNfu+KOWO9pw8p0w9b3ZJsRgFFe49UIR+LfkC2txkRwc4WbOfccuXd7xzGkPapf5TL0OJEniQ8YytJFAeJgTnshSAFbyYmF1aZXciLAGRse1CisbWbvOIca3PnL6pwPev9mAJSO2peiXduxOzgGdgXzuuIYgsC5OYe67nH9Ncq33nY+kgYHI/1GhFvcXwtjDYeFAZAEYKblzVJD1HOZ6fJwyrT2zNyqiv4C9G58dF1FxnDms6S01a0alBOZI+q65dk3+ys/zyyJDMwK0sSGRVWXUoX0bny4SUXu4yj/wJ38nypGX0wICAQ46ecyhQCiFscgUX65af7hy1YhJ/9YKtgDU9Y5Hhifn9Oka0c/zXwplm/MK3/JN6VT2tLxbCxBmqSu+XVejalrvyAITP3Yq6dUhKm6Jy9lg6tVP1X1GhC90HLGuX27N2aP02dX59k7a4vfxi6LBckQ+NNFtezkgprj8apZfrCcbZXUT0QON0T2P0gMZN7eArmdwfw9FXlRU9vtG7cvnrq39EDIhu/GgSBOoys2lfajhudY0AIsablhlvdILJWrDOq9cZ8ju1MrO/ZedVW8mIRfFqbtmb05aGeWW33v+tK5g5qnflF1wnM+IaCeoPjb2a/eH8CcIXjSTFkFZjXy5d3Vxuz+sOZhMh5JAXYZEeC0H0p3BHl8PxnjGNuFMkDUFp+aX73HjD55wMeu+oo8PbJ5ccDOrteS+AzHatpfLomWNi6oiewWk+I0DWhwJcCi72cOU1g2nwDyQjbgHTL4WZWupEnK5doltYZjKymiiKDUAK1kNKl55gH/A8Cr4ziL6xMiAAAAAElFTkSuQmCC');
    background-repeat: no-repeat;
    background-position: 10px center;
    width: 95px;
    background-color: #e3e3e3;
    border: 1px solid #c3c3c3;
  }
  &.facebook {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAABtklEQVRYR+2YrU7EQBSFz2H5E/wEDJIEjcHwCEAQJChwGF6Bd+AxsKAICAI8AhJBUBgEipAVG373kEsmBJrpdoa2u0PSUZv2tvfbc2buvSmR+GLifGgAyzpUi4KSWgCGgF8OdUm+xwJXBihpBMA+gF0AUx4QARgj+RYDWQmgpHkAtwZQkNwAX/sK6Ox8AjARkHic5EtA3HdIaQUl7QA4CEw6EMBrAIsBgB0AkyS7AbGVKti2xJ6kNwCWYi3NvqcKi59zDsc2ycMYtXyxVQDaqbQSk11rJM9TBlwneTYQQEkzrqyYclb/hj0gewCO3HU7GB2Sj7HA0RZLMpiobvADqlX7KZY0CiCq2DrAD9urJK3lBa+/KGi2RrUrR9MmOR1M5gL7CXhHcqEfgDZG3QOYdSOVr8QYhx0Ms9Ustd8nJLdqB8wmkJRXBzdInsYC1dFJ8gBXSV6kDLhC8rIBLFKgxx5sFCwS7+t+o2CQTD2C/rOCyRfqTZLHZR2KHhYiWl0DGOSOJBtebYjNrmT24AOAOQ/gMsmroH/ZI6iKPWjv8H1qi/qCkMdYGrCsQkXPN4BFChXdT17BT/XgqykOfjUoAAAAAElFTkSuQmCC');
    background-repeat: no-repeat;
    background-position: 10px center;
    width: 95px;
    background-color: #0A81EC;
  }




  &.grey {
    background: #e6e6e6;
    color: #000000;
  }

  &.btn-back {
    height: 39px;
    line-height: 39px;
    margin: 20px auto 40px;
    text-align: center;
    font-size: 0.9285714285714286rem;
    padding: 0 0 0 27px;

    &:hover {
      &:before {
        left: 0;
      }
    }

    &:before {
      background-position: 0 -57px;
      transform: rotate(180deg);
      top: 1px;
      left: 10px;
      right: auto;
      transition: all 300ms;
    }
  }
}

@keyframes blink {
  50% {
    width: 25px;
    height: 25px;
    opacity: 0.4;
    border-width: 1px;
    left: -2.5px;
    top: -2.5px;
  }
  100% {
    border-color: #dc0316;
    opacity: 1;
    border-width: 2px;
  }
}

.marks-box {
  margin-top: 55px;
  text-align: center;

  .container {
    text-align: center;
    float: none !important;
    width: auto !important;
    position: relative;

    .mark {
      display: inline-block;
      text-align: center;
      float: none !important;
      width: auto !important;
      position: relative;

      a {
        display: inline-block;
        margin: 0 10px 15px;
        text-align: center;
        font-size: 0.7142857142857143rem;
        height: 132px;
        padding: 0 0 8px;
        color: #252525;
        text-decoration: none;
        transition: 300ms all;
        background: #ebebeb;
        border-radius: 10px;
        font-weight: bold;
        clear: both;
        width: 73px;
        position: relative;
        vertical-align: top;

        @media only screen and (min-width: $tablet) {
          width: 107px;
          height: 178px;
          font-size: 0.8571428571428571rem;
        }

        img {
          margin: 0 0 6px;
          width: 100%;
        }

        &:hover {
          background: #dc0316;
          color: #fff;
        }

        span {
          display: block;
        }
      }

      &:hover {
        .remove {
          display: block;
        }
      }

      .remove {
        position: absolute;
        border-radius: 100%;
        width: 20px;
        height: 20px;
        background: #dc0316;
        top: -10px;
        right: 1px;
        display: none;
        cursor: pointer;

        &:before,
        &:after {
          content: '';
          height: 2px;
          width: 14px;
          background: #fff;
          position: absolute;
          top: 9px;
          left: 3px;
        }
        &:before {
          transform: rotate(45deg);
        }
        &:after {
          transform: rotate(-45deg);
        }
      }
    }
  }

  .btns {
    margin: 0;
    padding-bottom: 20px;
    border-radius: 0;

    .btn {
      margin: 0;
      float: right !important;
    }
  }
}

.login-page {
  .btn {
    margin: 0 10px 23px;
  }
}

.user-account {
  .text {
    margin-bottom: -20px;
  }

  .btns {
    .btn {
      display: block;
      margin: 0 auto 10px;
      float: none;

      @media only screen and (min-width: $tablet) {
        float: left;
      }

      &.black {
        @media only screen and (min-width: $tablet) {
          float: right;
        }
      }
    }
  }
}

.shakeit {
  animation: shakeit 1s ease-in-out infinite;
}

@keyframes shakeit {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.side-box {
  overflow: hidden;

  @media only screen and (min-width: $desktop) {
    max-width: 76%;
    margin: 0 auto;
  }

  h3 {
    margin: 0 0 10px;
    padding: 0;
  }

  p {
    padding: 0;
    margin: 30px 0 0;

    @media only screen and (min-width: $tablet) {
      width: 50%;
      float: left;
    }
  }
}
