@import '../../assets/styles/_variables';

.slot2 {
  position: absolute;
  z-index: 10;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 0 30px;
  margin: 100px 0 0;
  opacity: 0;
  top: 177px;
  transition: all 500ms;

  @media only screen and (min-width: $desktop) {
    margin: 0 auto;
    top: 69px;
    right: 0;
    left: 0;
    z-index: 30;
    padding: 0;
    opacity: 1;
    display: block;
    width: $desktop;
    bottom: auto;
    height: auto;
  }

  @media only screen and (min-width: $xdesktop) {
    width: $xdesktop;
  }

  ul {
    display: grid;

    @media only screen and (min-width: $desktop) {
      display: inline-block;
    }

    li {
      display: grid;
      align-items: center;

      @media only screen and (min-width: $desktop) {
        display: inline-block;
      }
    }
  }

  > * {
    max-height: 373px;
    height: 100%;
    width: 100%;
    display: grid;

    @media only screen and (min-width: $tablet) {
      max-width: 700px;
      margin: 0 auto;
    }

    @media only screen and (min-width: $desktop) {
      float: right;
      display: block;
      height: auto;
      width: auto;
      max-width: none;
    }
  }

  a {
    color: #fff;
    display: grid;
    height: 100%;
    font-size: 1.0714285714285714rem;
    padding: 0 12px;
    text-decoration: none;
    letter-spacing: 1px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    transition: all 500ms;
    display: grid;
    align-items: center;
    border-radius: 5px;

    @media only screen and (min-width: $desktop) {
      float: left;
      padding: 5px 12px;
      margin: 0;
      line-height: 1.5555555555555556rem;
      border: 0;
      display: block;
      border: 1px solid transparent !important;
      border-radius: 25px;
    }

    @media only screen and (min-width: $xdesktop) {
      padding: 5px 20px;
      margin: 0 6px;
    }

    &:hover,
    &.selected {
      background: rgba(255, 255, 255, 0.2);
      border: 0;

      @media only screen and (min-width: $desktop) {
        border: 1px solid #fff !important;
      }
    }

    &:last-child {
      border: 0;

      @media only screen and (min-width: $desktop) {
        margin-right: 0;
      }
    }
  }
}

.menu-show {
  .slot2 {
    opacity: 1;
    margin-top: -50px;
  }
}
