@import '../../assets/styles/_variables';

.top-bar {
  background: #141414;
  margin: 0 0 -1px;
  position: relative;
  text-align: right;

  > .row {
    overflow: visible;

    @include clearfix;
  }

  .navigation {
    display: none;

    @media only screen and (min-width: $desktop) {
      display: block;
    }

    ul {
      li {
        display: block;
        float: left;

        a {
          display: block;
          font-size: 1.2857142857142858rem;
          color: #fff;
          text-decoration: none;
          letter-spacing: 1px;
          padding: 10px;
          clear: both;

          @media only screen and (min-width: $tablet) {
            color: #fff;
            font-size: 0.8571428571428571rem;
            letter-spacing: 0.9px;
            padding: 6px 0 6px;
            display: inline-block;
            margin: 0 15px 0 0;
            clear: none;
          }

          @media only screen and (min-width: $desktop) {
            margin: 2px 40px 0 0;
          }

          &:hover {
            color: #ed1c24;
          }

          // &.social-icon {
          //   float: left;
          //   width: 30px;
          //   height: 30px;
          //   margin: 10px 10px;
          //   padding: 0;
          //   background: url(../../assets/images/social-icons.png) no-repeat 3px 2px;
          //   text-indent: -1000px;
          //   cursor: pointer;
          //   overflow: hidden;
          //   position: absolute;
          //   top: 0;
          //   right: 0;

          //   @media only screen and (min-width: $tablet) {
          //     float: right;
          //     width: 30px;
          //     height: 30px;
          //     margin: 0 0 0 7px;
          //     padding: 0;
          //     background: url(../../assets/images/social-icons.png) no-repeat 3px 2px;
          //     text-indent: -1000px;
          //     cursor: pointer;
          //     overflow: hidden;
          //     position: static;
          //   }

          //   &.instagram {
          //     background-position: -33px 2px;
          //     top: 50px;

          //     @media only screen and (min-width: $tablet) {
          //       background-position: -70px 2px;
          //     }
          //   }
          //   &.twitter {
          //     background-position: -70px 2px;
          //     top: 100px;

          //     @media only screen and (min-width: $tablet) {
          //       background-position: -33px 2px;
          //     }
          //   }
          // }
        }

        &:last-child {
          a {
            margin-right: 0;
            padding-right: 0;
          }
        }
      }
    }
  }
}
