@import '../../assets/styles/_variables';

.logo {
  background: url(../../assets/images/logo.png) no-repeat 173px 27px;
  background-size: 37px;
  float: left;
  color: #fff;
  text-decoration: none;
  position: relative;
  z-index: 30;
  transition: all 500ms;
  text-align: right;
  width: 230px;
  height: 99px;

  padding: 35px 76px 10px 0;
  margin: 2px;
  font-size: 1.142857142857142rem;
  letter-spacing: 1px;
  line-height: 1.3444444444444444rem;

  @media only screen and (min-width: $desktop) {
    padding: 53px 96px 10px 0;
    background-position: 162px 50px;
    background-size: 41px;
    font-size: 1.4285714285714286rem;
    letter-spacing: 2.3px;
    line-height: 1.7222222222222223rem;
    height: 140px;
  }

  &:hover {
    color: #fff;
    text-decoration: none;
  }
}
