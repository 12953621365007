@import '../../assets/styles/_variables';

.main-header {
 overflow: hidden;
 position: relative;
 height: 119px;
 transition: 500ms all;
 clear: both;

 background: rgba(0, 0, 0, 0.6);

 @media only screen and (min-width: $desktop) {
  height: 177px;
 }

 &:after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background: #fff;
  opacity: 0.16;
  float: left;
  margin: -1px 0 0;
  position: absolute;
  top: 100%;
 }

 .hamburger {
  float: right;
  width: 30px;
  height: 22px;
  margin: 48px 40px 0 0;
  cursor: pointer;
  position: relative;
  z-index: 10;

  @media only screen and (min-width: $desktop) {
   display: none;
  }

  &:before,
  &:after,
  span {
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   height: 3px;
   background: #fff;
   float: left;
  }

  &:before {
   content: '';
  }
  &:after {
   content: '';
   float: left;
   top: 20px;
  }
  span {
   float: left;
   top: 10px;
   transition: 250ms all;
   right: 35%;
  }
 }
}

.menu-show {
 main,
 .second-navigation,
 .main-footer {
  filter: blur(3px);
 }

 .main-nav {
  opacity: 1;
  top: 27px;
 }

 .main-header {
  height: 515px;

  .hamburger {
   &:before {
    animation: hamburgerLine1 500ms ease-in-out 0s forwards;
   }
   &:after {
    animation: hamburgerLine2 500ms ease-in-out 0s forwards;
   }
   span {
    opacity: 0;
   }
  }
 }
}

@keyframes hamburgerLine1 {
 0% {
 }
 50% {
  top: 10px;
  transform: rotate(0);
 }
 100% {
  top: 10px;
  transform: rotate(-45deg);
  background: #ed1c24;
 }
}
@keyframes hamburgerLine2 {
 0% {
 }
 50% {
  top: 10px;
  transform: rotate(0);
 }
 100% {
  top: 10px;
  transform: rotate(45deg);
  background: #ed1c24;
 }
}
