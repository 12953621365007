@import '../../assets/styles/_variables';

.slot4-container {
  background: #fff;
  padding: 20px 0;
  text-align: center;
  position: relative;
  z-index: 101;

  .descrption-box {
    padding: 0;

    p {
      color: #000;
      float: none;
      width: auto;
    }
  }

  .logos {
    text-align: center;
    padding: 15px 20px 5px;

    @media only screen and (min-width: $desktop) {
      padding: 15px 0 5px;
    }

    img {
      width: 50%;
      max-width: 266px;
      display: inline-block;

      @media only screen and (min-width: $desktop) {
        width: 224px !important;
        margin: 0px 7px;
      }

      @media only screen and (min-width: $xdesktop) {
        width: 266px !important;
      }
    }

    div {
      border-top: 1px solid #f4f4f4;
      margin: 30px 0 0;
      padding: 30px 0 0;

      @media only screen and (min-width: $desktop) {
        padding: 49px 0 0;
      }

      img {
        width: 33%;

        @media only screen and (min-width: $desktop) {
          margin: 0px 20px;
        }
      }
    }
  }
}

.home {
  .slot4-container {
    padding: 80px 0 20px;

    @media only screen and (min-width: $desktop) {
      padding: 55px 0 17px;
    }

    .slot4 {
      @media only screen and (min-width: $desktop) {
        padding: 76px 0 0;
      }
    }
  }
}
