@import '../../../assets/styles/_variables';

.messages {
  overflow: visible;
  margin-bottom: 50px;

  > div {
    background: #fff;
    padding: 20px 30px 15px;
    color: #000;
    border: 1px solid #e3e3e3;
    border-radius: 11px;

    @media only screen and (min-width: $tablet) {
      margin-bottom: -10px;
    }

    @media only screen and (min-width: $desktop) {
      padding: 30px 50px 25px;
      margin-bottom: -10px;
    }

    > div {
      padding: 0 0 5px;
    }
  }

  &.type-error {
    > div {
      border-color: #c37070;
      background: #ffebeb;
      color: #721c24;
    }
  }

  &.type-success {
    > div {
      border-color: #c3e6cb;
      background: #d4edda;
      color: #155724;
    }
  }

  &.type-warning {
    > div {
      color: #856404;
      background-color: #fff3cd;
      border-color: #ffeeba;
    }
  }

  &.small {
    margin: 15px auto;
    overflow: hidden;

    @media only screen and (min-width: $desktop) {
      margin: 30px auto -10px;
    }

    > div {
      margin: 0;
      padding: 15px 30px;
    }
  }
}

.messages {
  + .content {
    .contact,
    .user-registration {
      margin-top: 50px;
    }

    .login-page {
      margin-top: 0;
    }
  }

  &.popup {
    position: fixed;
    background: rgba(0, 0, 0, 0.7);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 500;
    max-width: none;
    width: auto;
    opacity: 0;
    transition: all 200ms;

    &.active {
      opacity: 1;
    }

    > div {
      position: fixed;
      top: 50%;
      margin: 0;
      left: 50%;
      max-width: none;
      width: auto;
      z-index: 500;
      transform: translate(-50%, -50%);
    }
  }
}
