@import '../../assets/styles/_variables';

.main-footer {
  margin: -1px 0 0;
  background: #f8f8f8;
  position: relative;
  z-index: 101;

  nav {
    padding: 18px 30px;
    overflow: hidden;

    @media only screen and (min-width: $tablet) {
      padding: 33px 30px;
    }

    a {
      text-decoration: none;
      font-size: 0.7857142857142857rem;
      line-height: 0.8888888888888888rem;
      color: #696969;
      letter-spacing: 1px;
      padding: 8px 9px;
      float: left;

      @media only screen and (min-width: $tablet) {
        font-size: 0.9285714285714286rem;
        line-height: 0.8888888888888888rem;
        padding: 0 16px;
        float: none;
        letter-spacing: 0;
      }

      &:hover {
        color: #ed1c24;
      }
    }
  }

  .slot5 {
    clear: both;

    @media only screen and (min-width: $desktop) {
      clear: none;
    }
  }

  .slot6 {
    padding: 24px 38px;
    font-size: 0.7857142857142857rem;
    line-height: 0.8333333333333334rem;
    color: #696969;
    border-top: 1px solid #eeeeee;

    @media only screen and (min-width: $tablet) {
      padding: 33px 46px;
      font-size: 1rem;
      line-height: 1rem;
    }
  }
}
