// tablets, 768px and up
$tablet: 768px;
$desktop: 992px;
$xdesktop: 1200px;

@mixin clearfix() {
  &::after {
    content: '';
    clear: both;
    display: table;
  }
}
