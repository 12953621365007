@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import '_reset';
@import '_variables';

html {
  font-size: 14px;
  line-height: 18px;
}

body {
  font-family: 'Open Sans', sans-serif;
  color: #000;
  font-size: 1rem;
  line-height: 1.5rem;
  overflow-y: scroll;

  background: #000 url(../../assets/images/book.webp) no-repeat 45% 0;

  @media only screen and (min-width: $desktop) {
    background-size: 100% auto;
  }

  @media only screen and (min-width: $xdesktop) {
    background-size: 130% auto;
  }
}

#root {
  overflow: hidden;
}

a {
  color: #ed1c24;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: #ed1c24;
  }
}

:focus {
  outline-color: #ed1c24 !important;
}
.home .btn:focus,
.swiper-slide.magazine a:focus {
  outline-color: #ed1c24 !important;
}
.btn:focus {
  outline-color: #000 !important;
}
.btn.black:focus {
  outline-color: #ed1c24 !important;
}

p {
  font-size: 1rem;
  line-height: 1.3333333333333333rem;
}

.center {
  text-align: center;
}

blockquote {
  padding: 20px;
  border-left: 5px solid #d7d7d7;
  background: #fff;
}
.ql-syntax {
  padding: 20px;
  background: #000;
  color: #fff;
}

.ql-indent-1 {
  padding-left: 0px;
  margin-left: 20px;
}
.ql-indent-2 {
  padding-left: 0px;
  margin-left: 40px;
}
.ql-size-small {
  font-size: 0.7857142857142857rem;
}
.ql-size-large {
  font-size: 1.4285714285714286rem;
}
.ql-size-huge {
  font-size: 2.142857142857143rem;
}
.ql-align-center {
  text-align: center;
}
.ql-align-right {
  text-align: right;
}
.ql-align-justify {
  text-align: justify;
}

.hide {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
}

.row {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;

  @media only screen and (min-width: $tablet) {
    max-width: $tablet;
  }
  @media only screen and (min-width: $desktop) {
    max-width: $desktop - 40;
  }
  @media only screen and (min-width: $xdesktop) {
    max-width: $xdesktop - 40;
  }
}

main {
  margin: 0;
  transition: all 350ms;
  padding: 50px 20px;
  background: #f6f6f6;

  @media only screen and (min-width: $tablet) {
    margin: 0;
  }

  @media only screen and (min-width: $tablet) {
    padding: 50px 20px;
  }

  @media only screen and (min-width: $desktop) {
    padding: 50px 0;
  }

  > .main {
    @media only screen and (min-width: $desktop) {
      display: grid;
      grid-template-columns: 16% 1fr 16%;
    }

    &.row {
      overflow: visible;

      @include clearfix;
    }

    .content {
      @media only screen and (min-width: $desktop) {
        margin: 0 50px;
      }
    }
  }

  > .content {
    > .row {
      overflow: visible;

      @include clearfix;
    }
  }
}

.wai-navigation {
  margin: 0;
  padding: 0;
  line-height: 0;

  li {
    float: left;
    margin: 0;
    padding: 0;
  }

  a {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    text-align: center;
    width: 250px;
    left: -500px;
    text-decoration: none;
    transition: opacity 0.3s ease 0s;
    background: #fff;
    color: #000;
    padding: 5px 10px;
    font-size: 1.1rem;
    line-height: 2.5rem;
    opacity: 0;
    z-index: 15;

    &:focus {
      opacity: 1;
      left: 0;
    }
  }
}

.switch-wrapper > div {
  position: relative;
  overflow: hidden;
}

.loading-icon {
  height: 50px;
  width: 50px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    margin: -35px 0 0 -25px;

    display: inline-block;
    padding: 0px;
    border-radius: 100%;
    border: 7px solid;
    border-top-color: #ed1c24;
    border-bottom-color: #fff;
    border-left-color: #ed1c24;
    border-right-color: #fff;
    animation: loader 1s ease-in-out infinite;
  }
}

.loading {
  @extend .loading-icon;

  height: 300px;
  width: 100%;
  position: relative;
}

@keyframes loader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

article {
  padding: 20px 0;

  h1 {
    margin: 0 0 20px;
    line-height: 1.3333333333333333rem;
  }
}

div.text {
  h2 {
    font-size: 2.142857142857143rem;
    line-height: 2.2222222222222223rem;
    letter-spacing: 2px;
    margin: 0 0 28px;
    padding: 0 0 25px;
    position: relative;
    font-weight: normal;

    &:before {
      content: '';
      position: absolute;
      border-top: 3px solid #707070;
      width: 50px;
      top: 100%;
      left: 50%;
      margin: 0 0 0 -25px;
    }
  }

  p {
    font-size: 1rem;
    line-height: 1.3333333333333333rem;
  }

  &.center {
    text-align: center;
  }
}

.slot1 {
  float: right;
  > * {
    float: left;
  }
}

main {
  overflow: visible;
  @include clearfix;

  .slot4,
  .slot9,
  .slot10 {
    @extend .row;
    margin-bottom: 50px;

    h2 {
      font-size: 2.142857142857143rem;
      line-height: 2.2222222222222223rem;
      letter-spacing: 2px;
      margin: 0 0 10px;
      padding: 0;
      font-weight: normal;
    }
  }

  .slot7,
  .slot8 {
    margin-bottom: 50px;

    @media only screen and (min-width: $desktop) {
      margin-bottom: 0;
    }

    h2 {
      font-size: 2.142857142857143rem;
      line-height: 2.2222222222222223rem;
      letter-spacing: 2px;
      margin: 0 0 10px;
      padding: 0;
      font-weight: normal;
    }
  }

  .slot4 {
    @media only screen and (min-width: $desktop) {
      margin-top: 50px;
    }
  }
}

.slot1 {
  .login-box {
    position: relative;

    .user {
      margin: 0 0 0 53px;
      padding: 0 18px 0;
      cursor: pointer;
      height: 30px;
      transition: all 300ms;

      svg {
        transform: scale(0.7);
      }
    }

    &:hover,
    &.focus {
      .user {
        background: #434343;
      }

      .content {
        height: 130px;
        opacity: 1;

        @media only screen and (min-width: $desktop) {
          height: 102px;
        }

        .login {
          padding: 20px 33px 30px;

          @media only screen and (min-width: $desktop) {
            padding: 20px 33px 30px;
          }

          .btns {
            width: auto;
            float: left;
            margin: 0;
            padding: 0 20px 0 0;

            a {
              display: block;
              margin: 0 0 5px;
              padding: 0;
              background: transparent;
              font-weight: normal;
              font-size: 1rem;
              line-height: 1rem;
              clear: both;
              color: white;
              position: relative;
              float: left;

              &:before {
                content: '';
                position: absolute;
                top: -5px !important;
                right: -28px;
                width: 30px;
                height: 30px;
                background: url(../../assets/images/assets.png) no-repeat 0 5px;
                transition: all 300ms;
                transform: scale(0.5);
              }

              &:hover {
                color: white;
                text-decoration: none;

                &:before {
                  right: -34px;
                }
              }
            }
          }

          .btn {
            float: right;
            margin: 5px 22px 0 0px;
            position: relative;
            bottom: -5px;

            @media only screen and (min-width: $desktop) {
              margin: 0 0 0 18px;
              bottom: 0;
            }
          }
        }
      }
    }

    .info {
      color: #fff;
      padding: 0 0 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      overflow: hidden;
      font-size: 1.0714285714285714rem;
      text-align: center;

      @media only screen and (min-width: $desktop) {
        padding: 0 0 16px;
      }
    }

    .content {
      position: absolute;
      transition: all 300ms;
      top: 30px;
      right: 0;
      height: 0;
      opacity: 0;
      overflow: hidden;
      border-radius: 0 0 10px 10px;
      z-index: 110;
      background: #434343;
      width: 250px;

      @media only screen and (min-width: $desktop) {
        width: 430px;
      }

      > div {
        padding: 30px 33px 30px 0;
        overflow: hidden;
      }

      .btn {
        width: auto;
        height: auto;
        line-height: 1.6111111111111112rem;
        padding: 5px 60px 6px 25px;
        letter-spacing: 1.1px;
        margin: -5px 0 20px 30px;
        clear: none;
        position: relative;
        display: block;

        &:before {
          top: 5px !important;
        }

        @media only screen and (min-width: $desktop) {
          float: right;
          margin: 0 0 0 18px;

          &:before {
            top: 5px;
          }
        }

        &:before {
          top: 5px;
          right: 10px;
          transition: all 300ms;
        }

        &:hover {
          &:before {
            right: 0px;
          }
        }
      }
    }
  }
}

main {
  aside {
    .slot7,
    .slot8 {
      > * {
        border-radius: 20px;
        background: #fff;
        padding: 20px 15px;
        font-size: 1.0714285714285714rem;
        overflow: hidden;
        margin-bottom: 20px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

        &.descrption-box {
          h2 {
            font-size: 1.4285714285714286rem;
            line-height: 1.3333333333333333rem;
            letter-spacing: 0px;
          }

          p {
            &:last-child {
              margin: 0;
            }
          }
        }
      }
    }
  }

  .login-box {
    .user {
      display: none;
    }

    .info {
      overflow: hidden;
      font-size: 1.0714285714285714rem;
      line-height: 1.1111111111111112rem;
      padding: 0 0 16px;
    }

    .content {
      margin: 0;

      .btns {
        margin: 0;
        padding: 0;
        max-width: none;

        .btn {
          width: 100%;
          float: none;
          margin: 0 0 5px !important;
        }
      }

      .btn {
        width: auto;
        height: auto;
        line-height: 1.6111111111111112rem;
        padding: 5px 0px 6px 10px;
        margin: 0 0 20px 0;
        font-size: 0.8571428571428571rem;
        display: block;

        &:before {
          top: 5px;
          right: 10px;
          transition: all 300ms;
        }

        &:hover {
          &:before {
            right: 0px;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .navigation {
    padding: 0 !important;

    ul {
      li {
        display: block;
      }
    }

    a {
      display: block;
      padding: 10px 20px;
      margin: 0;
      line-height: 1.5555555555555556rem;
      display: block;
      font-size: 1.0714285714285714rem;
      line-height: 1.1111111111111112rem;
      color: #000;
      transition: all 300ms;

      &:first-child {
        padding-top: 20px;
      }

      &:last-child {
        padding-bottom: 20px;
      }

      &:hover {
        background: #d7d7d7;
        text-decoration: none;
      }
    }
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

@import 'form';
@import 'main-menu';
@import 'footer-logos';
@import 'footer';
@import 'wcag';

.slot4-container {
  display: none;
}
.main-footer {
  display: none;
}

#root {
  .app {
    main {
      + .slot4-container {
        display: block;

        + .main-footer {
          display: block;
        }
      }
    }
  }
}

.grecaptcha-badge {
  z-index: 501;
}
